import React from "react"
import { graphql, navigate } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { homePagePaths, translate } from '../translate'
import Header from "../components/header"
import Footer from "../components/footer"
import Title from "../components/title"

const PageImageGallery = ({data}) => {
    const nodePage = data.nodePage
    return (
        <div>
          <Layout translationPaths={homePagePaths}>
            <Header />
              <Title title={nodePage?.title} color="bordeaux" type={1} className="size-56-line-64 d-flex justify-content-center space3" />
              <div dangerouslySetInnerHTML={{__html: nodePage?.body?.processed}} className="simple-page" />
            <Footer />
          </Layout>
        </div>
    )
}

export const pageQuery = graphql`
query PageImageGallery ($drupal_internal__nid: Int, $langcode: String!) {
    nodeImageGallery(
      drupal_internal__nid: {eq: $drupal_internal__nid},
      langcode:{eq: $langcode}
      ) {
        title
        relationships {
          field_image {
            uri {
              url
            }
          }
          field_video {
            uri {
              url
            }
          }
          field_category {
            name
          }
          field_tag {
            name
          }
        }
        created
      }
}`

export default PageImageGallery
